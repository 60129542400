// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_X6Iwot4BP",
    "aws_user_pools_web_client_id": "t3fvj0av2n7et6ndvkadpitdo",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://pqvk343otjetzlkyb7d44qvuxu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};
export default awsmobile;
