import React, { Component } from 'react';
import './App.css';
import select from './images/select.png';
import unselect from './images/unselect.png';
import close from './images/close.png';
import replacement from './images/poster_replacement_old.png';
import logoReplacement from './images/logo.png';
import './controls.css'

class CompareAP extends React.Component {
 

  constructor(props) {
    super(props);
    this.state = {
      showHideResolutionBtn: false,
      modalWidth: '0px',
      modalHeight: '0px',
      contentFinalList: [],
    };
    this.onInit();
  }

  onInit() {

    var userName = localStorage.getItem('userName');
    this.userNewMasterId = localStorage.getItem('userNewMasterId');
    this.baseUrl = localStorage.getItem("imageBaseURI");
    this.imageCacheValue = localStorage.getItem('imageCacheValue');
    localStorage.setItem('routefromComparePlayer', 'fromComparePlayer');

    if(userName) {

      const bodyTag = document.querySelector("body");
      const shakaScript = document.createElement("script");
		  const lcevcScript = document.createElement("script");

      shakaScript.setAttribute(
        "type",
        "text/javascript"
        );
        shakaScript.setAttribute(
        "src",
        "../../js/shaka-player.ui.debug.js"
        );
        bodyTag.appendChild(shakaScript);
  
        lcevcScript.setAttribute(
            "type",
            "text/javascript"
          );        
      lcevcScript.setAttribute(
        "src",
        "../../js/lcevc_dec.min.js"
        );
        bodyTag.appendChild(lcevcScript);

      fetch(this.baseUrl+'comparisons/'+this.userNewMasterId+'.json')
      .then(response => response.json())
      .then((jsonData) => {
          this.setState({
            contentFinalList: jsonData.Contents
          });
      })
      .catch((error) => {
        console.error(error)
      })

      localStorage.setItem('isAuthenticated', 'true');
      setTimeout(() => {
      
        this.setState({
          showHideResolutionBtn: true,
        });

        this.showResolutionBtn = document.getElementById("showResolution");
        this.resolutionSection = document.getElementById("resolutionContainer");
        this.hideResolutionBtn = document.getElementById("hideResolution");
        this.showResolutionBtn.style.display = "block";
        this.hideResolutionBtn.style.display = "none";
        this.resolutionSection.style.display = "none";
      
      }, 500);
    } else {
      this.props.history.push('/')
      window.location.reload();
    }

  }

  componentDidMount() {

    window.scrollTo(0, 0);
		this.connectionInterval = setInterval(() => {
			this.handleConnectionChange();
		}, 1000);

		this.video = document.getElementById('video');
    this.video.addEventListener("ended", this.isEnded);

		document.addEventListener('shaka-ui-load-failed', () => this.initFailed());

  }

  componentWillUnmount() {
    this.video.removeEventListener("ended", this.isEnded);
    clearInterval(this.connectionInterval);
    window.$("#playeralertModal").modal('hide');
    if(window.player) {
      window.player.unload();
      window.player.destroy();
    }
    clearInterval(this.seekInterval);
  }

  /*handleConnectionChange*/
	handleConnectionChange = () => {
		this.video = document.getElementById('video');
		const condition = navigator.onLine ? 'online' : 'offline';
		if(condition == 'offline') {
			this.video.pause();
      this.killPlayer();
			window.$("#playeralertModal").modal('show');
			window.$('#playeralertbody').html('There appears to be a problem with your internet connection. Please check connections and retry.');
		}
	}


  /*IsEnded function*/
  isEnded = (event) => {
    if (document.fullscreenElement) {
        document.exitFullscreen();
    }
    var modal = document.getElementById('nativePlayerPopupModal');
    modal.style.display = "none";
  }
/*End of IsEnded function*/

/*For Video Resolution*/
recalcRates() {
  this.video = document.getElementById("video");
    this.d1 = document.getElementById("totalresolution");
  if (this.video) {

    this.videoStats = window.player.getStats();

    if(window.LCEVCdec) {

      this.d1.innerHTML = window.LCEVCdec.instance ? "Video Resolution : " + window.LCEVCdec.instance.frameWidth + " X " + window.LCEVCdec.instance.frameHeight : 0;

      let fps = window.LCEVCdec.instance ? window.LCEVCdec.instance.frameRate : 0;
      fps = fps ? fps.toFixed(0) : '0';
      if(fps > '0') {
         document.getElementById('frameRate').innerHTML = "Frame Rate : " + fps + ' fps';
      }
    }

    const tracks = window.player.getVariantTracks();
    for (var iterator = 0; iterator < tracks.length; iterator++) {
      if (tracks[iterator].active) {

        var bitrateVal = (tracks[iterator].bandwidth / 1024).toFixed(0);

        if (bitrateVal > 999) {
          document.getElementById( 'videoBitrate' ).innerHTML = 'Video Bitrate : ' + (bitrateVal / 1000).toFixed(1) + ' Mbps';
        } else {
          document.getElementById( 'videoBitrate' ).innerHTML = 'Video Bitrate : ' + bitrateVal + ' Kbps';
        }
        
        break;
      }
    }


    if (document.getElementsByTagName('img')[2].hidden) {
      if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('avc1')) {
      var videoCodec = 'H.264';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('av01')) {
      var videoCodec = 'AV1';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('vp09')) {
      var videoCodec = 'VP9';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hvc1')) {
      var videoCodec = 'HEVC';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hev1')) {
        var videoCodec = 'HEVC';
      }
    } else {
      if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('avc1')) {
      var videoCodec = 'LCEVC H.264';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('av01')) {
      var videoCodec = 'LCEVC AV1';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('vp09')) {
      var videoCodec = 'LCEVC VP9';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hvc1')) {
      var videoCodec = 'LCEVC HEVC';
      } else if(window.player && tracks[0] && (tracks[0].codecs != undefined && tracks[0].codecs != null) && tracks[0].codecs.includes('hev1')) {
        var videoCodec = 'LCEVC HEVC';
      }
    }

    if(videoCodec) {
    document.getElementById( 'videoCodec').innerHTML = 'Video Codec : ' + videoCodec;
    }

  }
}


render() {

  this.iOSCheck = (/iPhone/i.test(navigator.userAgent));

  this.showPlayer = (manifestUrl, type) => (event) => {

    if(manifestUrl) {

      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition == 'offline') {
        alert('There appears to be a problem with your internet connection. Please check connections and retry.');
      } else {

        if(type == 'native') {
          var nplayerWidth =  window.$(window).width() * (1/2)
          var nplayerHeight = (9 / 16 ) * nplayerWidth
          this.setState({
            modalWidth: nplayerWidth,
            modalHeight: nplayerHeight,
          });
        } else {
          var lplayerWidth =  window.$(window).width() * (3/4)
          var lplayerHeight = (9 / 16 ) * lplayerWidth
          this.setState({
            modalWidth: lplayerWidth,
            modalHeight: lplayerHeight,
          });
        }

        var modal = document.getElementById('nativePlayerPopupModal');
        modal.style.display = "block";

          const video = document.getElementById('video');
					const ui = video['ui'];
					const controls = ui.getControls();
					const player = controls.getPlayer();

					window.player = player;
  				window.ui = ui;

					// Enable the LCEVC enhancement.
					player.configure('lcevc.enabled', true);
					player.configure('lcevc.drawLogo', true);

					// Enable LCEVC decoding on Safari
					player.configure('streaming.useNativeHlsOnSafari', false);
					player.configure('mediaSource.forceTransmux', true);

					// Listen for error events.
					player.addEventListener('error', this.onError);
					controls.addEventListener('error', this.onError);

					// Try to load a manifest.
					// This is an asynchronous process.
					player.load(manifestUrl).then(() => {
						// This runs if the asynchronous load is successful.
						console.info('The video has now been loaded!'); // eslint-disable-line
					}).catch(this.onError); // onError is executed if the asynchronous load fails.

					this.seekInterval = setInterval(() => {
						this.recalcRates();
					}, 1000);
          
      }
      
    } else {
      window.location.reload();
    }

  }/*End of show player*/


  this.onError = (error) => {
		console.error('Error code', error.code, 'object', error);
		var codec = localStorage.getItem('codec');
		window.$("#playeralertModal").modal('show');

    var modal = document.getElementById('nativePlayerPopupModal');
    modal.style.display = "none";

    clearInterval(this.seekInterval);

		if (error.message.includes('CONTENT_UNSUPPORTED_BY_BROWSER')) {
			window.$('#playeralertbody').html('This video format is not supported by your browser.');
		} else if(codec == '3' && error.code == '4032') {
			window.$('#playeralertbody').html('Your player supports LCEVC but unfortunately does not support H265.');
		} else if(error.code == '4032') {
			window.$('#playeralertbody').html('Stream is not supported by the Shaka player.');
		} else {
			window.$('#playeralertbody').html('Something went wrong, Please try again.');
		}
	}

  this.hideResolution = () => (event)  => {
    this.showResolutionBtn.style.display = "block";
    this.hideResolutionBtn.style.display = "none";
    this.resolutionSection.style.display = "none";
  }
  
  this.showResolution = () => (event) => {
    this.showResolutionBtn.style.display = "none";
    this.hideResolutionBtn.style.display = "block";
    this.resolutionSection.style.display = "block";
  }


    this.killPlayer = () => (event) => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      const video = document.getElementById('video');

      if (typeof video !== "undefined" && video !== null) {
        video.pause();
        video.currentTime = 0;
      }
      var modal = document.getElementById('nativePlayerPopupModal');
      modal.style.display = "none";
      clearInterval(this.seekInterval);
      clearInterval(this.connectionInterval);
    }

    this.onListError = (event)  => {
      event.target.style.objectFit = 'contain';
      event.target.style.border = '1px solid gray';
      event.target.src = replacement;
    }

    this.onLogoError = (event)  => {
      event.target.src = logoReplacement;
    }


    const parentdiv = {
      backgroundColor: '#fff',
      color: '#000',
      minHeight: '100vh',
    }


    return (
      <div style={parentdiv}>
        
        {/*Container*/}
        <div className="container-fluid ap_container_font">
          <div className="row">

            {/*LOGO and TITLE*/}
            <div className="col-md-12 col-sm-12 col-xs-12 display_flex padding_zero">
              <div className="col-xs-4 col-sm-3 ap_title_logo_container">
                <img className="ap_title_logo" src={(this.baseUrl +'source/companylogo_' +this.userNewMasterId+ '.png'+ '?' + this.imageCacheValue)} onError={(e) => {e.persist();this.onLogoError(e)}} />
              </div>
              <div className="col-xs-8 col-sm-9">
                <p className="ap_title_container">LCEVC-enhanced versus native comparisons</p>
              </div>
            </div>


            {/*Contents*/}
            <div className="col-md-12 col-sm-12 col-xs-12 ap_content_container">
              {
                (this.state.contentFinalList.length > '0')?
                ( <span>
                    {this.state.contentFinalList.map((contents,i) =>
                      <div key = {i} className="col-lg-4 col-md-6 col-sm-6 col-xs-12 ap_content_list">
                        <div><img src={contents.NativeContents.Image} className="width_100 ap_placeholder" onError={(e) => {e.persist();this.onListError(e)}} /></div>
                        <div className="ap_btn_container">
                          <div className="ap_native_btn" onClick={this.showPlayer(contents.NativeContents.Manifest, 'native')}>
                            <div className="ap_native_text">Native H.264</div>
                          </div>
                          <div className="width_4"></div>
                          <div className="ap_lcevc_btn" onClick={this.showPlayer(contents.LcevcContents.Manifest, 'lcevc')}></div>
                        </div>
                      </div>
                    )}
                  </span>
                )
                :(<span></span>)
            }
            </div>
          {/*End of Contents*/}

          </div>
        </div>
      {/*End of the Container*/}

      {/*Modal Window*/}
      <div className="modal fade" id="nativePlayerPopupModal" role="dialog">
        <div id="nativePlayermodal" className="modal-content" style={{width : this.state.modalWidth , height : this.state.modalHeight}}>

            
            <div data-shaka-player-container id="videoContainer" style={{maxWidth: '100%'}}>
            
              <video data-shaka-player id="video" playsInline autoPlay={!this.iOSCheck} style={{width: this.state.modalWidth, height: this.state.modalHeight, backgroundColor: '#000000'}}></video>
                      
              {/*LCEVC logo, fullscreen and info buttons*/}
              <div id="top_label" className="ptoplabel">
                <div className="ptoplabelcontainer">

                  { 
                  (this.state.showHideResolutionBtn)?( 
                    <img id="hideResolution" onClick={this.hideResolution()} className="compare_presoultionbtn" src={select} />
                  ) : ( <span></span> ) 
                  }
                  
                  <img id="showResolution" onClick={this.showResolution()} className="compare_presoultionbtn" src={unselect} />
                  <div id="closeplayer" onClick={this.killPlayer()} className="material-icons pfullscreenbtn"><img src={close} alt="fullscreen" className="width_14" /></div>
                </div>
              </div>

              {/*Video Resolution and other infos*/}
              <div id="resolutionContainer" className="presolutionContainer">
                <div className="presolutioncontent">
                  <div id="totalresolution"></div>
                  <div id="videoBitrate"></div>
                  <div id="videoCodec"></div>
                  <div id="frameRate"></div>
                </div>
              </div>

          </div>


        {/*End of compare_player_pplayercontainer*/}
          
        </div>        
      </div>
      {/*End of Modal Window*/}

        <div className="modal fade" id="playeralertModal" role="dialog">
          <div className="modal-dialog" >
            <div className="modal-content">
              <div className="modal-body" id="playeralertbody">
              </div>
              <div className="playeralert_modal_btn_container">
                <span className="cursor_pointer playeralert_modal_btn" data-dismiss="modal">Ok</span>
              </div>
            </div>
          </div>
        </div>

      </div>   
    );
  }

}

export default CompareAP;